.sd{
    padding:1rem;
}

.sd.left{
	grid-area: sd-left;
}

.sd.right {
	grid-area: sd-right;
}