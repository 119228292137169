.dropdown-container {
    width: 100%; /* Adjust as needed */
    margin: 10px auto;
    font-family: Arial, sans-serif;
    
}

.dropdown-container select {
    -webkit-appearance: none;
    appearance: none;

    width: 100%;
    padding: 4px 4px;
    border: none;
    border-radius: 5px;
    background-color: #666666; /* Dark background */
    color: white; /* Light text */
    font-size: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    justify-content: center;
}





.dropdown-container select:focus {
    outline: none;
    box-shadow: 0 0 5px rg#666666; /* Highlighted focus state */
}

.dropdown-container option {
    background-color: #8d8d8d; /* Slightly lighter option background */
    color: white;
}
/* 
.select-label{
    display: block;
    margin-bottom: 0.5em;
    color: #E0E0E0;
    font-weight:bolder;

} */

