.winner-winner-backdrop {
    position: fixed; /* Position fixed to make it cover the entire screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    backdrop-filter: blur(1px); /* Blur effect for the background */
    display: flex; /* Flexbox to center the modal */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    z-index: 1000; /* High z-index to make sure it's above other content */
}
.winner-winner-modal {
    background-color: #393939;
    border: #121212;
    border-width: 5px;
    border-style:groove;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: auto; /* Adjust width as needed */
    max-width: 90%; /* Limit maximum width to ensure it doesn't get too wide on large screens */
    font-family: 'Oswald', Arial, sans-serif;

}

.winner-winner-header {
    font-family: 'Oswald', Arial, sans-serif;
    font-size: 2em; /* Adjust the size as needed */
    font-weight: normal; /* Reset font weight if needed */
    line-height: 1.2; /* Adjust line height as needed */
    margin: 1em;
    
}


.winner-winner-dialog {
    border: #121212;
    border-width: 5px;
    border-style:groove;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: auto; /* Adjust width as needed */
    max-width: 90%; /* Limit maximum width to ensure it doesn't get too wide on large screens */
}
.winner.button-box {
    background-color: #313131;
    padding: 10px;
    display: flex;
    flex-basis: .5;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: inset 2px 2px 3px 2px #121212;
    
}

.winner.big-button{
    margin: 7px;
    width: auto;
}

.winner-winner-body{
    font-family: 'Roboto', Arial, sans-serif;
    margin: 4px;
}

.winner-winner-body-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 10px;
    font-family: 'Roboto', Arial, sans-serif;
}

.winner-winner-button-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    padding: 5px;
}

.winner-winner-box{
    background-color: #313131;
    color:aliceblue;


}

.close-button-container{
    display: flex;
    width: 100%;
    align-items: left;
}
.close-button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: lightcoral;
    width:2.5em;
    height: 2.5em;
    margin: 5px;
    background-color: #313131;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color:dimgrey;
}

.close-button:hover{
    border-style: groove;
    color: gray;
}


