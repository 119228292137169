@font-face {
  font-family: "dseven-mono";
  font-style: normal;
  font-weight: normal;
  src: url("../../fonts/digital-7-mono.ttf");
}

.timer-container {
  display: flex;
  justify-content: right;
  margin: 5px;
  padding: 2px 2px;
  background-color: #181818;
  border-radius: 3px;
  border-width: 4px;
  border-color: #666666 #666666 #404040 #404040;
  border-style: solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: min-content;
}

.timer {
  font-size: 2.2em;
}

.timer-display {
  position: absolute;

  font-family: "dseven-mono", monospace; /* Using the custom font */
  color: #ffc72c;
  margin: 0px 5px;
  z-index: 10;
  pointer-events: none;
  /* filter: blur(0.2px); */
}

.timer-background {
  color: #333333;
  font-family: "dseven-mono", monospace;
  margin: 0px 5px;
}
