.xword-holder {
  position: relative;
  display: inline-block;
  background-color: #232323;
  border-radius: 10px;
  width: 100%;
}
.button-div {
  width: 40%;
  display: flex;
  margin: 0px 5px 5px 5px;
  padding: 5px;
}

.layout-wrapper{
  position: relative;
}

.timer-wrapper{
  position: relative;
  display: inline-block;
}

.dadiv {
  position:relative;
  display: inline-block;
}

.crossword-disabled {
  position: relative;
}

 .crossword-disabled::after {
  z-index: 2;
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: rgba(255, 0,0, 0.5); */
  border-radius: 10px;
  background-color: rgba(35,35,35, 0.5);
} 

.xword-holder.crossword-disabled::after {
  top: 0;
}


.big-button.crossword {
  z-index: 3;
  width: 100%;
  /* height: auto; */
  margin: 5px;
  font-size: 1.2em;
  font-weight: bold;
  border-radius: 10px;
  box-shadow: 4px 4px 0px #1e1e1e;
  border: none;
  cursor: pointer;
  transition: all 0.1s ease-out;
}

.clue-div {
  display: grid;
  text-align: left;
  grid-template-columns: 50% 50%;
  line-height: 2;
  padding-left: 2em;
  color: #e0e0e0;
  font-family: 'Roboto',Arial, Helvetica, sans-serif;
}

.clue{
  text-align: left;
  line-height:1.2;
  margin-bottom:1em;
}

.crossword-layout {
  position: relative;
  display: grid;
  grid-template-columns: 40% 60%;
  padding: 1em;
  justify-content: center;
}

/* .highlighted-clue{
    margin: 5px;
    background-color: lightblue;
    pointer-events: None;

} */

*.focus {
  outline: none;
}

.crossword.correct {
  /* rect {
    stroke: rgb(100, 200, 100) !important;
  } */
  svg > rect {
    fill: rgb(100, 200, 100) !important;
  }
  text {
    fill: rgb(100, 200, 100) !important;
  }
}

.clue.correct {
  ::before {
    content: "\u2713";
    display: inline-block;
    text-decoration: none;
    color: rgb(100, 200, 100);
    margin-right: 0.25em;
  }

  text-decoration: line-through;
  color: #b0b0b0;
}

.crossword {
  font-family: 'Oswald', Arial, sans-serif;
  padding: 5px;
  margin: 0px;
  background-color: rgb(17, 17, 17);
  border-radius: 10px;
  box-shadow: 4px 4px 0px #1e1e1e;
}

@media screen and (max-width: 800px) {
  .crossword-layout {
    display: flex;
    flex-direction: column;
  }

  .clue-div {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    padding-left: 0.5em;
  }

  *.focus {
    outline: none;
  }
}

