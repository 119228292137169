.waiting-modal-backdrop {
  position: fixed; /* Position fixed to make it cover the entire screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  backdrop-filter: blur(1px); /* Blur effect for the background */
  display: flex; /* Flexbox to center the modal */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1000; /* High z-index to make sure it's above other content */
}

.waiting-modal-content {
  background-color: #313131;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  text-align: center;
  width: auto; /* Adjust width as needed */
  max-width: 90%; /* Limit maximum width to ensure it doesn't get too wide on large screens */
  font-family: Arial, Helvetica, sans-serif;
  color: #cdcdcd;
}

.spinner {
  -webkit-box-shadow: inset 0px 0px 0px 2px #000, 0px 0px 0px 2px white;
  -moz-box-shadow: inset 0px 0px 0px 2px #000,0px 0px 0px 2px white;
  box-shadow: inset 0px 0px 0px 2px #000, 0px 0px 0px 2px white, 0px 0px 20px 0px #000;
  animation: spin 5s cubic-bezier(1,1.9,0,0.62) infinite;
  /* animation: spin 4s ease-in-out infinite; */

  border-radius: 7px;
  width: 30px;
  height: auto;
  border-color: #000000;
  border-width: 2px;
  border-style: solid;
  opacity: 0.85;
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(90deg);
  }
  25% {
    transform: rotate(90deg);
  }
  45% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(270deg);
  }
  70% {
    transform: rotate(540deg);
  }
  75% {
    transform: rotate(540deg);
  }
  
  95% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
