.page-header {
  grid-area: hd; /* Align with your layout's grid area name for the header */
  background-color: #121212;
  text-align: center;
  padding: 1rem 0rem 0rem 0rem;
  margin:1rem;
  transition: transform 0.5s ease;
}

.header__title {
  margin: 0;
  font-size: 2.5rem;
  color: #00337A;
}

.header__subtitle {
  margin: 0;
  font-size: 12pt;
  color: #555;
  font-style: italic;
}
@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg) scale(1.05); }
  /* 10% { transform: translate(-1px, -2px) rotate(-1deg) scale(1.05); } */
  20% { transform: translate(-3px, 0px) rotate(0.5deg) scale(1.02); }
  /* 30% { transform: translate(3px, 2px) rotate(0deg) scale(1.05); } */
  40% { transform: translate(1px, -1px) rotate(0.5deg) scale(1.05); }
  /* 50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.05); } */
  60% { transform: translate(-3px, 1px) rotate(0deg) scale(1.03); }
  /* 70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.05); } */
  80% { transform: translate(-1px, -1px) rotate(0.5deg) scale(1.05); }
  /* 90% { transform: translate(1px, 2px) rotate(0deg) scale(1.05); } */
  100% { transform: translate(1px, -2px) rotate(-0.5deg) scale(1.05); }
}

@keyframes shake2 {
  0% { transform: translate(1px, 1px) rotate(0deg) ; }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(0.5deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(0.5deg) ; }
  /* 50% { transform: translate(-1px, 2px) rotate(-1deg) scale(1.05); } */
  60% { transform: translate(-3px, 1px) rotate(0deg) ; }
  /* 70% { transform: translate(3px, 1px) rotate(-1deg) scale(1.05); } */
  80% { transform: translate(-1px, -1px) rotate(0.5deg) ; }
  /* 90% { transform: translate(1px, 2px) rotate(0deg) scale(1.05); } */
  90% { transform: translate(1px, -2px) rotate(-0.5deg) ; }
  100% { transform: translate(0px, 0px) ; }


}
/* 
.header:hover {
  transform: scale(1.05); 
  cursor: pointer;
} */

.page-header:hover {
  /* transform: scale(1.05); */
  animation: shake2 0.5s; /* Apply the shake animation */
  /* animation-iteration-count: infinite; Make it shake continuously */
  cursor: default;
}

.header-link{
  text-decoration: none;
}

