form {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  width: 80%;
  height: 100%;
}

body {
  background-color: #121212;
}

.input {
  width: auto;
  padding: 1.2rem 2rem;
  outline: none;
  border: 1px solid grey;
  margin: 24px 0;
  font-size: 18px;
}
/* .big-button {
  height: 100%;
  width: 100%;
  color: #eaeaea;
  margin: 5px 5px;
  padding: 10px 20px;
  border-radius: 8px;
  border-width: 0px;
  box-shadow: 0px 4px 0px #002456, 0px 4px 0px 3px #121212;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.big-button:hover {
  box-shadow: inset 5px 7px 11px -12px #ffffff, 0px 4px 0px #002456,
    0px 4px 0px 3px #121212;
  cursor: pointer;
} */

.big-button:active {
  transform: translateY(2px);
  box-shadow: inset 5px 7px 11px -10px #ffffff, 0px 2px 0px #002456,
    0px 2px 0px 3px #121212;
}

label {
  /* display: block; */
  margin-bottom: 0.5em;
  color: #e0e0e0;
  font-weight: bold;
}

.select-label {
  padding: 20px;
  flex: 2;
}

.label-container {
  display: flex;
  flex-direction: column;
  grid-column: 1;

  width: 200px;
}

.label-container,
.button-container {
  padding: 10px; /* adjust as needed */
  display: flex;
  align-items: center;
}

.button-container {
  height: auto;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 3;
}

.top-half,
.bottom-half {
  width: auto;
  flex-grow: 0.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  color: #535353;
  width: 200px;
  height: 0px;
  border-style: solid;

}
.divider-container{
  display: flex;
  flex-direction: row;
  align-content: center;
}
.or{
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 2px;
}

.label-and-button-container {
  width: 100%;
  height: fit-content;
  display: grid;
  align-items: center;
  grid-template-columns: 8fr 1fr 4fr; /* 2/3 for the first column, 10% for the gap, 1/3 for the second column */
}
