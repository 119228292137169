footer {
    display: flex;
    justify-content: center;
}

.ft {
    grid-area:ft;
    font-family: 'Helvetica', sans-serif;
    font-size: 8px;
    color: gray;

    

}

.copyright-statement{
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-content:center;
    text-align: center;

}
