.holy-grail-container {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr minmax(auto, 960px) 1fr;
  grid-template-areas:
    "hd hd hd"
    "sd-left main sd-right"
    "ft ft ft";
  height: 100vh;
  background-color: #121212;
  color: #e0e0e0;
  font-family: "Helvetica", "Arial", sans-serif;
  border-radius: 5px;
}

.hd {
  grid-area: hd;
  /* Add header styles */
}

.main {
  grid-area: main;
  background-color: #313131;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* height: 100%;  */

  max-width:960px;
  min-width: fit-content;

  padding: 10px;
  /* Add main content styles */
}

.sd-left {
  grid-area: sd-left;
  /* Add left sidebar styles */
}

.sd-right {
  grid-area: sd-right;
  /* Add right sidebar styles */
}

.ft {
  grid-area: ft;
  border-radius: 15px;
  padding: 1rem;
  margin: 25px;
}

@media (max-width: 768px) {
  .holy-grail-container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "hd"
      "main"
      "sd-left"
      "sd-right"
      "ft";
    justify-content: center;
    grid-template-rows: auto auto auto auto auto;

    height: 100vh;
    width: auto;
  }

  .main{
    margin:20px;
  }

  /* Adjust sidebar styles for mobile */
  .sd-left,
  .sd-right {

    display: none;
    /* Optional: Hide sidebars on mobile */
  }
}
